//
// Header
//

.header {
    position: relative;
}

@include media-breakpoint-down(xs) {
    //css for login page mob
    .header.login-header {
       padding-top: 214px !important;
    }
}