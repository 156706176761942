
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    font-size: 15px;
    background: #fff;
}

.dropdown > .btn.dropdown-toggle.btn-light{
  height:50px;
}
body  p {
  font-size: 15px;
}
/* ===============================
  Login page css
================================*/

body .bg-gradient-info {
  background: linear-gradient(87deg, #747ccb 0, #182388 100%) !important;
}
body.bg-default {
  background-color: #fff !important;
}
body .separator-skew .fill-default {
  fill: #fff;
}
body .text-light {
  color: #3E5463 !important;
}
.paward-logo {
  width: 100%;
  text-align: center;
}
.paward-logo img {
  max-width: 90px;
}
.paward-logo span {
  display: inline-block;
  margin: 0px 10px;
}
/* ===============================
  loader
================================*/
.loader-wrapper {
  position: absolute;
  left: 45%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 9;
}
.loader img {
  max-width: 100%;
  margin: 0 auto;
}
.img-preloader-1 {
  margin-left: 21px !important;
}
/* ===============================
  sidebar
================================*/
.pro-item-content {
  color: #00000080 !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 10px 8px 10px !important;
}
.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 100% !important;
  min-width: 100% !important;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper,
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item,
.pro-sidebar > .pro-sidebar-inner {
  background-color: #fff !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 10px;
  width: 25px;
  min-width: 25px;
  height: 28px;
  line-height: 25px;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: initial !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 47px !important;
}
.pro-sidebar .navbar-vertical .navbar-nav .nav-link.active ,
body .navbar-light .navbar-nav .nav-link.active{
  color: #5e72e4;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 5px 1px !important;
}
body .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 3px 16px 1px 5px !important;
}
.pro-sidebar .pro-menu a:before {
  display: none !important;
}
.badge{
  color: white;
}

/* ===============================
  Form styling
================================*/
body .form-group input:focus,
body .form-group textarea:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #C4C4C4;
  color: #333;
  
}
body .login-wrapper .form-group input:focus {
  border: none;
}
.login-wrapper .input-group {
  box-shadow: none;
  border: 1px solid #ededed;
  border-radius: 8px; 
  outline: none;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  height: 50px;
}
.login-wrapper .input-group  .form-control , .login-wrapper .input-group .input-group-prepend{
  border-radius: 8px; 
}

body .login-wrapper .btn.btn-primary {
  display: inline-block;
  padding: 13px 30px !important;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  background: #0a4d8d;
  border: 1px solid #0a4d8d;
  box-shadow: inset 0 0 0 0 #ffffff;
  border-radius: 50px;
  transition: 0.7s;
  position: relative;
}
.login-wrapper h5 {
  font-weight: 600;
  font-size: 16px;  

}
body .selectpicker {
  font-weight: 400;
}
body .form-group label {
  font-weight: 400;
  color: #272b41;
  font-size: 15px;
}
body .form-control {
  border: 1px solid #C4C4C4;
  min-height: 46px;
  padding: 3px 15px;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  font-size: 15px;
  box-shadow: none;
}
.card .dropdown-item:hover,
.card .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #fff;
}
body .login-img img {
  max-width: 80px;
  margin-bottom: 30px;
  border-radius: 10px;
}
body .form-control:disabled,
body .form-control[readonly] {
  background-color: #e3ebf3;
}
body .custom-radio .custom-control-input ~ .custom-control-label {
  padding-left: 31px;
}
body .custom-control-label::before,
body .custom-control-label::after {
  left: 0px;
}
body .custom-control {
  padding-left: 0px;
}
body .form-group {
  margin-bottom: 20px;
}
body .custom-radio .custom-control-input:checked ~ .custom-control-label::after,
body
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  left: 8px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dorp-file-bg {
  background: #fbfbfb !important;
  border: 2px dashed rgba(0, 0, 0, 0.1) !important;
  min-height: 93px;
  position: relative;
  height: 93px;
  padding: 6px;
  padding-top: 17px;
  margin-bottom: 10px;
  font-size: 14px;
}
.dorp-file-bg.disabled {
  pointer-events: none;
}
.dorp-file-bg input {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0px;
}
.dorp-file-bg label {
  text-align: center;
  z-index: 10;
  margin: 0px auto;
}

body .css-1s2u09g-control {
  min-height: 44px;
}
body .disabled .css-1s2u09g-control  {
    background-color: #e3ebf3;
    pointer-events: none;
}
/* Css for */
body .card {
  padding: 15px;
}


.tab-4 .nav-item {
  width: 25%;
  text-align: center;
}
.tab-5 .nav-item {
  width: 20%;
  text-align: center;
}
.nav-tabs span.count-number {
  background: #ddd;
  border-radius: 40px;
  width: 25px;
  height: 25px;
  display: inline-block;
  line-height: 25px;
  margin-left: 17px;
  font-size: 11px;
  color: #000;
}

body .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  font-size: 14px;
  padding: 8px 4px;
  color: #4caf50;
  width: 100%;
  font-size: 14px;
  min-height: 46px;
}
body .card table .thead-light th {
  color: #000;
  letter-spacing: inherit;
  text-transform: capitalize;
  font-size: 14px !important;
}
body .card table td {
  color: #5e5b5b;
}
body  table  .custom-control {
  margin: 0px;
}
body .card {
  padding: 20px;
}
.card .table {
  margin-bottom: 0;
  margin-top: 20px;
}
body .footer {
  background: transparent;
}
/* ===============================
 btn style css for save button  
================================*/

body  .btn {
  display: inline-block;
  padding: 9px 30px;
  font-size: 17px;
  font-weight: 600;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 50px;
  position: relative;
  box-shadow: none;
}
body  .btn.btn-primary {
  color: #fff;
  background: #72a03b;
  border: 1px solid #8dcb4b;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
body  .btn.btn-save:hover {
  color: #4caf50 !important;
  background: #fff;
  border: 1px solid #8bc34a;
  transition: all 0.5s;
  -webkit-transform: none;
  transform: none;
}
body .btn-save,
body .btn-delete,
body .btn-close {
  border: none;

}


body .btn-light-sky-blue {
  background-color: #f6f9fc;
  color: #8898aa;
  border-color: #adb5bd;
  padding: 8px 15px;
}
body  .shadow.card  .btn , body .modal .btn,
body .card table .btn{
  padding: 9px 25px;
  border-radius: 5px;
  font-size: 14px;
}
body .shadow.card .br-30.btn
{
  border-radius: 30px;
}
body .btn-small.btn {
	padding: 9px 6px;
    font-size: 11px;
    font-weight: 500;
    box-shadow: none;
    text-align: center;
    float: none;
    width: 36px;
    height: 34px;
    line-height: 1;
    margin: 0 3px;
}
body .btn-small.btn:hover{
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
body .btn-small .icon,
body .btn-small i {
	font-size: 13px;
    line-height: 1;
    line-height: 18px;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.btn-inline{
	padding-top:25px;
}
body .btn-xs {
    padding: 5px 8px;
}
.display-flex {
    display: inline-flex;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #f79a6f;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before{
	border-bottom-color: #f79a6f;
}
.bg-info-light {
	background-color: rgb(255 186 0 / 0.1) !important;
    color: #f79a6f !important;
}
.bg-info-light:hover {
	background-color:  #f79a6f !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-primary-light:hover {
	background-color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-danger-light:hover {
	background-color: #e63c3c !important;
}

.bg-warning-light {
	background-color: rgba(255, 152, 0,0.12) !important;
	color: #f39c12 !important;
}
.bg-warning-light:hover  {
	background-color: #f39c12 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
}
.bg-success-light:hover {
	background-color: #26af48 !important;
}

.bg-purple-light {
	background-color: rgba(197, 128, 255,0.12) !important;
	color: #c580ff !important;
}
.bg-purple-:hover {
	background-color:  #c580ff !important;
}

.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.bg-default-light:hover {
	background-color: #283447 !important;
}
.bg-default-light:hover .fa {
	color:  rgba(40, 52, 71,0.12) !important;
}
body .btn-small.btn:hover * {
    color: #fff;
}
/* ===============================
text color css 
================================*/
.text-red {
  color: #e70a1e;
}
.text-green {
  color: #307a18;
}
.text-gray {
  color: #525f7f;
}
.text-blue {
  color: #5e71e3;
}
/* ===============================
Table css 
================================*/
table button {
  padding: 5px 11px !important;
  font-size: 13px !important;
}
table tr td,
table th {
  padding: 10px !important;
  font-family: Open Sans, sans-serif;
  font-size: 15px !important;
}

.card  table.table-style-1 {
	border-spacing: 0 10px;
    border-collapse: separate;
}
.card  table.table-style-1 >thead {
    vertical-align: bottom;
}
.card  table.table-style-1 tr {
    border: 0 solid;
    border-color: inherit;
}
.card  table.table-style-1 tbody tr {
    background: #fff;
}
.card   table.table-style-1 tbody tr td {
    border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	vertical-align: middle;
	color: #272b41;
	font-weight: 400;
	padding: 10px 3px;
}
.card  table.table-style-1 thead th {
	background: #f2f6ff;
    color: #272b41;
}
.card  table.table-style-1 tbody td:first-child,
.card  table.table-style-1 thead th:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding-left: 15px;
}
.card  table.table-style-1 tr td:last-child ,
.card  table.table-style-1 thead th:last-child{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding-right: 15px;
}
table.table-style-1 thead tr th {
    border-bottom: none;
    font-weight: 600;
    color: #757575;
    text-transform: initial;
    letter-spacing: initial;
    border-top: none;
}

.card  table.table-style-1 tbody tr td .form-control{
	border: 1px solid #e1e9fd;
}
/* ===============================
Modal css 
================================*/

body .modal-lg {
  max-width: 95%;
}
body .main-content .modal-content {
  padding: 30px;
}
body .modal {
  background: rgb(0 0 0 / 56%);
}
body button.modal-close-top,
body button.modal-close-top:hover {
  position: absolute;
  right: 16px;
  top: 15px;
  border-radius: 0px;
  background: #890fc7;
  border: none;
  color: #fff;
  font-size: 18px;
  width: 32px;
  height: 30px;
  line-height: 34px;
  z-index: 99;
  opacity: 1;
  padding: 0px !important;
}
h5.modal-title {
  font-size: 22px;
  color: #31093b;
  padding: 0;
  padding-left: 10px;
  display: block;
  width: 100%;
}
h5.modal-title span {
  float: right;
  font-size: 14px;
  padding-right: 17px;
}
.modal .modal-footer {
  display: block;
}
.modal .modal-header {
  background: #f5f5f5;
}

body .modal-footer {
  padding: 0 10px 20px;
}
/*css for when modal titel is empty*/
.modal-header-close.modal-header .close {
  position: absolute;
  right: 16px;
  background: #fff;
  z-index: 999;
  opacity: 1;
  background: #e80f0f;
  height: 51px;
  width: 40px;
  text-align: center;
  padding: 0px;
  line-height: 60px;
  color: #fff;
}
.modal-header-close.modal-header {
  padding: 0px;
}
.Doctor-details-admin-panel .list-group-item {
  background-color: #4c57b4;
  color: #fff;
  font-size: 14px;
}
.Doctor-details-admin-panel .list-group-item:hover {
  background: #32073c;
}
.tab-4 {
  overflow: hidden;
}
.scroll {
  /* width: 1000%; */
  overflow-x: auto;
  border-bottom: 2px solid #DDD;
  overflow-y: hidden;
  white-space: nowrap;
  display: block!important;
  flex-wrap: nowrap;
  /* max-width: 100%; */
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.scroll .nav {
  /* display: flex; */
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 2000px;
}
.scroll .nav-tabs {
  padding-bottom: 8px;
}

body .tab-4 .nav-item {
  width: 263px;
}
.scroll::-webkit-scrollbar , #sidenav-main::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 7px;
  display: block;
  height: 4px;
}

.scroll::-webkit-scrollbar-thumb , #sidenav-main::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: #4c57b4;
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
/* Track */
.scroll::-webkit-scrollbar-track, #sidenav-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 


/* =================
   Master Pages Css
======================*/
.card .input-group-alternative.input-group input:focus,
.card .input-group-alternative.input-group input:hover  {
  border-color: transparent;
}
.card.ripple {
  transition: all 0.3s ease;
  overflow: hidden;
}
.master-details-section .card {
  display: block;
  padding: 7px;
  min-height: 81px;
}
.master-details-section .nav-tabs .nav-link.active,
.master-details-section .nav-tabs:hover .nav-link {
  background: none;
  border: none;
}
.master-details-section .nav-tabs .nav-link {
  border: none;
}
.master-details-section .card .col-image {
  width: 65px;
  float: left;
}
.master-details-section .card .card-img {
  width: 50px;
  height: 50px;
  background: #3c0846;
  /* padding: 5px; */
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.master-details-section .card .card-img img {
  max-width: 30px;
  text-align: center;
}
.master-details-section .card.ripple:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background-color: #7c42b9;
  z-index: 1;
  transition: all 0.9s;
}
.master-details-section .card .col-data {
  width: calc(100% - 65px);
  float: right;
  text-align: left;
  padding-left: 10px;
  z-index: 9;
  position: relative;
  padding-top: 13px;
}
.master-details-section .card.ripple:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: url(../img/brand/hover-black.png) no-repeat;
  z-index: 1;
  transition: all 0.9s;
  background-size: cover;
}
.master-details-section .card.ripple:hover:after,
.master-details-section .active.nav-link .card.ripple:after {
  left: 0;
}
.master-details-section .card.ripple:hover:before,
.master-details-section .active.nav-link .card.ripple:before {
  right: 0;
  opacity: 1;
}
.master-details-section .card:hover h3,
.master-details-section .active.nav-link h3 {
  color: #fff;
}
.master-details-section .card h3 {
  font-weight: 500;
  font-size: 14px;
}

.master-details-section .tab-content {
  background: #f5f5f5;
  padding: 8px;
}
.master-details-section table {
  background: #fff;
}
.delete-img {
  max-width: 50px;
}
.modal .shadow.card {
  box-shadow: none !important;
  border: none;
}
.modal .header.bg-gradient-info.pb-8.pt-5.pt-md-8 {
  padding: 0px !important;
}
.modal .mt--7.container-fluid {
  margin-top: 0px !important;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
  float: right;
  width: 100%;
  text-align: right;
  margin-top: 20px;
}
.pagination li.page-item {
  display: inline-block;
}

/*==========================================
process modal css
===============================================*/
.nav-tabs-5-style li.nav-item {
  width: 20%;
  text-align: center;
}
.nav-tabs-5-style .nav-tabs .nav-link.active{
  background-color: #0a4d8d;
    color: #fff;
}
.nav-tabs-5-style .nav-link {
  font-size: 14px;  
  width: 100%;
}
.card .change-photo-btn input {
  bottom: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 170px;
}
.card  .change-photo-btn.disabled {
  background-color: #e3ebf3;
  pointer-events: none;
  color: #857d7d;

}

.card .change-photo-btn {
  background-color: #20c0f3;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 13px 19px;
  position: relative;
  text-align: center;
  transition: all .3s ease 0s;
  width: 181px;
}
.card.card-style {
    padding: 20px;
    box-shadow: none;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
    padding: 20px;
}
body .add-btn {
  background: transparent;
  border: none;
  color: #20c0f3;
}
button.add-btn span {
  background: #20c0f4;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  height: 19px;
  line-height: 16px;
  padding: 2px 3px 2px 2px;
  width: 19px;
}
.pull-right {
  float: right;
}
.btn-danger {
  background-color: #ff3547!important;
  color: #fff;
}
/* ================
payment-receipts
=====================*/
.payment-receipts .card .form-control ,
.payment-receipts  .input-group-text  ,
.payment-receipts .card  .form-control:focus,
.payment-receipts .card  .form-control:hover {
  border: 1px solid #ddd !important;
}
.payment-receipts .card .input-group input.form-control{
  border-left: none !important;
}
.payment-receipts  .input-group-alternative {
  box-shadow:none;
}

@media (min-width: 1200px) {
  body .modal-lg {
    max-width: 1100px;
  }
}
@media (min-width: 768px) {
  body .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: initial;
  }
  .navbar-vertical .navbar-collapse:before {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .main-content .modal-lg {
    max-width: 90%;
  }
  body .modal-md {
    max-width: 800px !important;
  }
}
@media (max-width: 991px) {
  body .modal .nav-tabs .nav-link {
    min-height: 60px;
  }
  .main-content .container-fluid {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
@media (max-width: 767px) {
  .main-content .tab-4 .nav-item {
    width: 180px;
  }
    .pro-item-content {
      color: #172b4d !important;
    }
    h5.modal-title span {
      float: none !important;
      display: inline-block;
    }
    .modal .tab-5 .nav-item {
      width: 50%;
      text-align: center;
   }
   .modal .tab-pane .card ,  .modal .tab-pane .card .card-body {
      padding: 0px;
   }
   .modal .card.card-style {
    padding: 20px;
   }
   .modal .tab-pane .card.card-body {
    padding: 14px !important;
   }
  .nav-tabs-5-style li.nav-item {
    width: 100%;
  }
  body .header.login-header {
    padding-top: 115px !important;
  }
  body .login-wrapper {
    padding: 0px;
  }
  body .modal .nav-tabs .nav-link {
    min-height: auto;
  }  
}
